@import '../../../../styles/customMediaQueries.css';

.sectionContainer {
  display: flex;
  flex-direction: column;
  gap: 26px;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;

  @media (--viewportMedium) {
    flex-direction: row;
    align-items: stretch;
    max-width: var(--contentMaxWidthPages);
    min-height: 460px;
  }
}

.overnightCampingInfo h3 {
  color: var(--colorBlueTextDark);
  font-size: 16px;
  font-weight: var(--fontWeightBold);
  margin-bottom: 2px;
  line-height: 1.5em;

  @media (--viewportMedium) {
    font-size: 32px;
    line-height: 48px;
  }
}

.overnightCampingInfo p {
  color: var(--colorBlueDark);
  font-size: 14px;
  font-weight: var(--fontWeightRegular);
  line-height: unset;
  margin-bottom: 20px;
  margin-top: 0;

  @media (--viewportMedium) {
    font-size: 20px;
    line-height: 30px;
  }
}

.overnightCampingInfo {
  border-radius: 16px;
  background: var(--colorBlueLight);
  margin: 0 auto;
  padding: 32px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (--viewportMedium) {
    padding: 0 60px;
    width: 66%;
    align-items: flex-start;
  }
}

.cardDataContainer {
  max-width: 398px;
  margin: 0 auto;

  @media (--viewportMedium) {
    width: 33%;
  }
}

.routerLink {
  margin-top: 20px;

  @media (--viewportMedium) {
    margin-top: 0;
  }

  &:hover {
    text-decoration: none;
  }
}

.button {
  text-transform: uppercase;
  width: calc(100vw - 70px);
  border-radius: 8px;
  min-height: 36px;

  @media (--viewportMedium) {
    max-width: 302px;
  }
}

.sectionItem {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  background-color: var(--colorWhite);
  height: 100%;

  @media (--viewportMedium) {
    border-radius: 16px;
    cursor: pointer;
    transition: 0.2s;
    max-width: 398px;
    min-height: 500px;
    background-color: var(--colorBlueLight);
  }

  &:hover {
    transform: translateY(-5px);
  }
}

.imgContainer {
  flex: 50%;
  overflow: hidden;
  height: 100%;
}

.titleContainer {
  display: flex;
  flex-direction: column;
}

.contentContainer {
  flex: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 16px;
  height: calc(100% - 210px);
}

.image {
  height: 210px;
  background-size: cover;
  background-position: center center;
  border-radius: 8px 8px 0px 0px;
}

.contentTitle {
  color: var(--colorBlueDark);
  font-size: 16px;
  font-style: normal;
  font-weight: var(--fontWeightBold);
  line-height: normal;
  margin: 0;
  padding: 0;
  text-transform: capitalize;

  @media (--viewportMedium) {
    font-size: 20px;
  }
}

.itemContainer {
  position: relative;
  height: 100%;
}

.availabilityBadge {
  position: absolute;
  left: 0;
  top: 30px;
  padding: 5px 10px;
  background-color: var(--colorBlueLight);
  color: var(--colorBlueButton);
  font-weight: var(--fontWeightBold);
  text-transform: uppercase;
  font-size: 12px;

  @media (--viewportMedium) {
    font-size: 15px;
    padding: 5px 20px;
  }
}

.location {
  color: var(--colorBlueDark);
  font-size: 16px;
  font-style: normal;
  font-weight: var(--fontWeightMedium);
  line-height: 18px;
  margin: 0;
  padding: 0;
  text-transform: capitalize;
}

.content {
  color: var(--colorBlueDark);
  font-size: 12px;
  font-weight: var(--fontWeightRegular);
  line-height: 27px;
  max-height: 180px;
  overflow: auto;
  margin: 10px 0;

  @media (--viewportMedium) {
    font-size: 18px;
  }
}

.listingNamedLink {
  &:hover {
    text-decoration: none !important;
  }
}

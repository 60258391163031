@import '../../../../styles/customMediaQueries.css';

.p {
  max-width: 80ch;
  margin-top: 0;
  margin-bottom: 0;
  letter-spacing: 0;
  color: var(--colorBlueTextDark);
  font-size: 14px;
  font-weight: 400;
  line-height: normal;

  /* Handle margin-top of next adjacent element against this p element */
  & + p,
  & + ul,
  & + ol,
  & + pre,
  & + div,
  & + h1,
  & + h2,
  & + h3,
  & + h4,
  & + h5,
  & + h6 {
    margin-top: 24px;
  }

  @media (--viewportMedium) {
    font-size: 20px;
  }
}

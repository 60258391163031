@import '../../../../styles/customMediaQueries.css';

.sectionDetails {
  max-width: var(--contentMaxWidthPages);
  display: grid;
  justify-content: flex-start !important;
  margin: 0 auto;
  padding: 0 20px;
  position: relative;

  & h1 + p,
  & h1 + a,
  & h1 + ul,
  & h1 + ol,
  & h1 + code,
  & h1 + div,
  & h1 + h1,
  & h1 + h2,
  & h1 + h3,
  & h1 + h4,
  & h1 + h5,
  & h1 + h6 {
    margin-top: 12px;
  }

  & h2 + p,
  & h2 + a,
  & h2 + ul,
  & h2 + ol,
  & h2 + code,
  & h2 + div,
  & h2 + h1,
  & h2 + h2,
  & h2 + h3,
  & h2 + h4,
  & h2 + h5,
  & h2 + h6 {
    margin-top: 12px;
  }

  & h3 + p,
  & h3 + a,
  & h3 + ul,
  & h3 + ol,
  & h3 + code,
  & h3 + div,
  & h3 + h1,
  & h3 + h2,
  & h3 + h3,
  & h3 + h4,
  & h3 + h5,
  & h3 + h6 {
    margin-top: 12px;
  }

  & h4 + p,
  & h4 + a,
  & h4 + ul,
  & h4 + ol,
  & h4 + code,
  & h4 + div,
  & h4 + h1,
  & h4 + h2,
  & h4 + h3,
  & h4 + h4,
  & h4 + h5,
  & h4 + h6 {
    margin-top: 12px;
  }

  & h5 + p,
  & h5 + a,
  & h5 + ul,
  & h5 + ol,
  & h5 + code,
  & h5 + div,
  & h5 + h1,
  & h5 + h2,
  & h5 + h3,
  & h5 + h4,
  & h5 + h5,
  & h5 + h6 {
    margin-top: 12px;
  }

  & h6 + p,
  & h6 + a,
  & h6 + ul,
  & h6 + ol,
  & h6 + code,
  & h6 + div,
  & h6 + h1,
  & h6 + h2,
  & h6 + h3,
  & h6 + h4,
  & h6 + h5,
  & h6 + h6 {
    margin-top: 12px;
  }

  @media (--viewportMedium) {
    justify-content: center;
    padding: 40px 0 0 0;
  }
}

.title {
  color: var(--colorBlueTextDark);
  font-size: 20px;
  font-style: normal;
  font-weight: var(--fontWeightMedium);
  line-height: 28px;
  letter-spacing: normal;
  padding: 0;
  margin: 0;

  @media (--viewportMedium) {
    font-size: 28px;
  }
}

.description {
  color: var(--colorBlueTextDark);
  font-size: 16px;
  font-weight: var(--fontWeightBold);
  line-height: normal;
  margin: 0;
  padding: 0;
  margin-top: 10px !important;
  margin-bottom: 0px !important;

  @media (--viewportMedium) {
    font-size: 20px;
  }
}

.sectionContainer {
  display: flex;
  flex-direction: row;
  gap: 26px;
  margin: 0 auto;
  scroll-behavior: smooth;
  width: 100vw;
  overflow-x: scroll;
  padding: 0 20px;

  @media (--viewportMedium) {
    max-width: 1150px;
    justify-content: center;
    overflow-x: auto;
    padding: 0;
  }
}

.sectionItem {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  background-color: var(--colorBlueLight);
  height: 100%;

  @media (--viewportMedium) {
    width: calc(33.33% - 13.33px);
    border-radius: 16px;
    cursor: pointer;
    transition: 0.2s;
    width: 100%;
    min-height: 500px;
  }

  &:hover {
    transform: translateY(-5px);
  }
}

.imgContainer {
  flex: 50%;
  overflow: hidden;
  height: 100%;
}

.contentContainer {
  flex: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 16px;
  height: calc(100% - 210px);
}

.image {
  height: 210px;
  background-size: cover;
  background-position: center center;
  border-radius: 8px 8px 0px 0px;
}

.contentTitle {
  color: var(--colorBlueDark);
  font-size: 20px;
  font-style: normal;
  font-weight: var(--fontWeightBold);
  line-height: normal;
  margin: 0;
  text-transform: capitalize;
  padding: 0;
}

.itemContainer {
  position: relative;
  height: 100%;
}

.availabilityBadge {
  position: absolute;
  left: 0;
  top: 30px;
  padding: 5px 10px;
  background-color: var(--colorBlueLight);
  color: var(--colorBlueButton);
  font-weight: var(--fontWeightRegular);
  text-transform: uppercase;
  font-size: 12px;

  @media (--viewportMedium) {
    padding: 5px 20px;
    font-size: 15px;
    font-weight: var(--fontWeightBold);
  }
}

.location {
  color: var(--colorBlueDark);
  font-size: 16px;
  font-style: normal;
  font-weight: var(--fontWeightMedium);
  line-height: 18px;
  margin: 0;
  padding: 0;
  text-transform: capitalize;
}

.content {
  color: var(--colorBlueDark);
  font-size: 18px;
  font-weight: var(--fontWeightRegular);
  line-height: 1.5em;
  max-height: 180px;
  margin: 10px 0;
  overflow: auto;
}

.carouselContainer {
  display: flex;
  align-items: center;
  scroll-behavior: smooth;
  padding: 0;

  @media (--viewportMedium) {
    padding: 20px 0 0 0;
  }
}

.carouselArrows {
  opacity: 1;
  z-index: 2;
  transition: all ease-in-out 500ms;
}

.carouselArrowPrev,
.carouselArrowNext {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 2;
  width: 38px;
  height: 38px;
  padding: 0px 0 4px 0;
  margin-top: -64px;
  border-radius: 100%;
  border: none;
  font-weight: var(--fontWeightRegular);
  backdrop-filter: blur(2px);
  background-color: rgb(255 255 255 / 29%);
  opacity: 0.9;
  color: var(--colorWhite);
  transition: all ease-in-out 100ms;

  &:hover {
    opacity: 0.9;
    cursor: pointer;
    background: var(--colorBlueDark);
    color: white;
  }
}

.carouselArrowPrev {
  left: 48px;
}

.carouselArrowNext {
  right: 48px;
}

.notEnoughBlocks {
  @media (--viewportMedium) {
    display: none;
  }
}

.listingNamedLink {
  min-width: 300px;
  width: calc(33.33% - 13.33px);

  @media (--viewportMedium) {
    min-width: 366px;
  }

  &:hover {
    text-decoration: none;
  }
}

.titleContainer {
  display: flex;
  flex-direction: column;
}

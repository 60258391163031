@import '../../../../styles/customMediaQueries.css';

.root {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;
  max-width: 1140px;

  @media (--viewportLargeWithPaddings) {

    max-width: 100%;
  }
  &:nth-of-type(odd) {
    background-color: var(--colorBlueLight);
  }
}

.sectionContent {
  padding: 14px 0;
  position: relative;

  @media (--viewportMedium) {
    padding: 20px;
    width: calc(100% - 40px);
    max-width: 1140px;
  }
  @media (--viewportLargeWithPaddings) {
    padding: 20px;
    width: 100%;
    max-width: 100%;
  }
}

.backgroundImageWrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@import '../../../../styles/customMediaQueries.css';

.sectionDetails {
  max-width: var(--contentMaxWidthPages);
  display: grid;
  justify-content: flex-start !important;
  margin: 0 auto 0 auto;
  padding: 0 20px;
  position: relative;

  & h1 + p,
  & h1 + a,
  & h1 + ul,
  & h1 + ol,
  & h1 + code,
  & h1 + div,
  & h1 + h1,
  & h1 + h2,
  & h1 + h3,
  & h1 + h4,
  & h1 + h5,
  & h1 + h6 {
    margin-top: 12px;
  }

  & h2 + p,
  & h2 + a,
  & h2 + ul,
  & h2 + ol,
  & h2 + code,
  & h2 + div,
  & h2 + h1,
  & h2 + h2,
  & h2 + h3,
  & h2 + h4,
  & h2 + h5,
  & h2 + h6 {
    margin-top: 12px;
  }

  & h3 + p,
  & h3 + a,
  & h3 + ul,
  & h3 + ol,
  & h3 + code,
  & h3 + div,
  & h3 + h1,
  & h3 + h2,
  & h3 + h3,
  & h3 + h4,
  & h3 + h5,
  & h3 + h6 {
    margin-top: 12px;
  }

  & h4 + p,
  & h4 + a,
  & h4 + ul,
  & h4 + ol,
  & h4 + code,
  & h4 + div,
  & h4 + h1,
  & h4 + h2,
  & h4 + h3,
  & h4 + h4,
  & h4 + h5,
  & h4 + h6 {
    margin-top: 12px;
  }

  & h5 + p,
  & h5 + a,
  & h5 + ul,
  & h5 + ol,
  & h5 + code,
  & h5 + div,
  & h5 + h1,
  & h5 + h2,
  & h5 + h3,
  & h5 + h4,
  & h5 + h5,
  & h5 + h6 {
    margin-top: 12px;
  }

  & h6 + p,
  & h6 + a,
  & h6 + ul,
  & h6 + ol,
  & h6 + code,
  & h6 + div,
  & h6 + h1,
  & h6 + h2,
  & h6 + h3,
  & h6 + h4,
  & h6 + h5,
  & h6 + h6 {
    margin-top: 12px;
  }

  @media (--viewportMedium) {
    justify-content: center;
    padding: 0;
    margin: 0 auto 10px auto;
  }
}

.title {
  color: var(--colorBlueTextDark);
  font-size: 20px;
  font-style: normal;
  font-weight: var(--fontWeightMedium);
  line-height: 1;
  letter-spacing: 1px;
  padding: 0;
  margin: 0;

  @media (--viewportMedium) {
    font-size: 28px;
  }
}

.description {
  color: var(--colorBlueTextDark);
  font-size: 16px;
  font-style: normal;
  font-weight: var(--fontWeightBold);
  line-height: normal;
  margin: 0;
  padding: 0;
  margin-top: 10px !important;
  margin-bottom: 10px !important;

  @media (--viewportMedium) {
    font-size: 20px;
    margin-bottom: auto;
  }
}

.sectionContainer {
  background-color: var(--colorWhite);
  margin: 0 auto;
}

.locationsSectionContainer {
  max-width: var(--contentMaxWidthPages);
  margin: 0 auto;
  width: 100%;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (--viewportMedium) {
    flex-direction: row;
    gap: 0;
    padding: 0 0 30px 0;
  }
}

.locationCard {
  transition: 0.2s;
  display: block;
  width: 100%;
  margin-right: 20px;
  border-radius: 8px;
  background: var(--colorBlueLight);
  height: 320px;

  @media (--viewportMedium) {
    height: 480px;
    display: inline-block;
    vertical-align: top;
    width: calc(33.33% - 13.33px);
  }

  &:hover {
    text-decoration: none;
    transform: translateY(-5px);
  }
}

.locationCard .imgContainer {
  overflow: hidden;
}

.locationCard .locationImg {
  height: 260px;
  border-radius: 8px 8px 0% 0%;
  width: 100%;
  background-size: cover;
  background-position: center center;
  object-fit: cover;

  @media (--viewportMedium) {
    height: 404px;
  }
}

.locationsTitle {
  padding: 0 20px;
  color: var(--colorBlueDark);
  white-space: normal;
  font-weight: var(--fontWeightBold);
  font-size: 20px;
}
